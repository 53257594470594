.m-info-box {
  display: flex;
  margin-bottom: -30px;
  margin-right: floor($grid-gutter-width / -2);
  margin-left: floor($grid-gutter-width / -2);

  @media screen and (max-width: $screen-sm-max) {
    flex-wrap: wrap;
  }

  &.flex-column {
    margin-right: 0;
    margin-left: 0;
  }

  &__col {
    margin-bottom: 30px;
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 auto;

    &--media {
      @media screen and (min-width: $screen-xs-min) {
        min-width: 395px;
        padding-right: floor($grid-gutter-width / 2);
      }
    }

    &--media-last {
      @media screen and (min-width: $screen-xs-min) {
        min-width: 395px;
        padding-left: floor($grid-gutter-width / 2);
        order: 10;
      }
    }

    &--media-top {
      @media screen and (min-width: $screen-xs-min) {
        flex: 0 0 auto;
      }
    }

    &--media-bottom {
      order: 10;
    }

    .m-info-box.flex-column & {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__image {
    width: 100%;
    height: 260px;
    object-fit: cover;

    @media screen and (min-width: $screen-xs-min) {
      width: auto;
      height: auto;
    }
  }

  &__action {
    margin: 0;
  }
}
