.m-header {
  padding: ($base-spacing / 2);

  @media screen and (min-width: $grid-float-breakpoint) {
    @include custom-scrollbar();
    overflow-y: auto;
    padding: $grid-gutter-height 0;
  }

  &__wrapper {
    @media screen and (min-width: $grid-float-breakpoint) {
      height: 100%;
      width: $desktop-nav-width;
      padding: 0 $base-spacing;

      .m-layout__header--with-offset & {
        width: $desktop-nav-width - $m-layout-offset-size;
      }
    }
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: $grid-float-breakpoint) {
      @include text-break();
      flex-direction: column;
      height: 100%;
      padding-left: $m-layout-header-scrollbar-width; // For equalization content with scrollbar empty space on the right side of header
    }

    &--middle {
      display: flex;
      justify-content: center;
    }
  }

  &__bottom {
    margin-top: auto;
    width: 100%;

    .m-header__content--middle & {
      margin-top: $grid-gutter-height;
    }
  }

  &__contact {
    @include text-ellipsis();
    margin-bottom: ($base-spacing / 2);
  }
}
