.m-hero {
  min-height: calc(100vh - #{$m-layout-header-mobile-height});
  display: flex;
  flex-direction: column;
  position: relative;
  padding: floor($grid-gutter-height / 2) 0 floor(1.5 * $grid-gutter-height) 0;

  @media screen and (min-width: $grid-float-breakpoint) {
    min-height: 100vh;
    padding: 75px 0;
  }

  &__cover-wrapper {
    @include position(absolute, 0 0 0 0);

    &--sticky {
      position: fixed;
      z-index: -1;

      @media screen and (min-width: $grid-float-breakpoint) {
        width: 100vw;
      }
    }
  }

  &--auto-height {
    min-height: auto;
  }

  &__cover-item {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__bg-slider,
   &__bg-img {
    height: 100%;
    z-index: 1;
  }

  &__contact-box {
    display: flex;
    flex-shrink: 0;
    padding: 0 floor($grid-gutter-width / 2);
    max-width: 100%;
    z-index: 1;
  }

  &__info-boxes {
    padding: 0 floor($grid-gutter-width / 2);
    max-width: 100%;
    z-index: 1;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding: 0 floor($grid-gutter-width * 2);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: $slider-content-max-width + $slider-content-gutter;
    max-width: calc(100% - #{$grid-gutter-width * 2});
    z-index: 1;

    @media screen and (min-width: $grid-float-breakpoint) {
      max-width: 90%;
    }

    &--gradient {
      width: auto;

      @media screen and (min-width: $grid-float-breakpoint) {
        max-width: 95%; // It can't be 100% because it breaks `settings.hero_position`
      }
    }

    &--with-arrows {
      padding: 0 $grid-gutter-width;
      max-width: 100%;

      @media screen and (min-width: $grid-float-breakpoint) {
        max-width: calc(100% - #{$grid-gutter-width * 2});
      }
    }
  }

  &__btn {
    min-width: 180px;
  }
}
