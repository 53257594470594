.m-info-boxes {
  @include custom-scrollbar();
  overflow-x: auto;
  max-width: 100%;
  margin: 0;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin: 0 floor($grid-gutter-width / 2);
  }

  &__list {
    display: flex;
  }

  &__list-item {
    padding: $grid-gutter-height $grid-gutter-width;
    margin: floor($grid-gutter-height / 2) floor($grid-gutter-width / 4);
    flex-grow: 1;
    flex-basis: calc(100% / 3);
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin: $grid-gutter-height floor($grid-gutter-width / 2);
    }
  }
}
