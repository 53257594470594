.m-contact {
  &__list {
    margin: $base-spacing 0;
    display: flex;
    flex-direction: column;

    &--vertical {
      align-items: center;
    }

    &--horizontal {
      @media screen and (min-width: $grid-float-breakpoint) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &--cross {
      @media screen and (min-width: $screen-lg-min) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  &__item {
    text-align: center;
    padding: floor($base-spacing / 2);

    @media screen and (min-width: $grid-float-breakpoint) {
      padding: $base-spacing floor($base-spacing / 2);
    }

    &--horizontal {
      @media screen and (min-width: $grid-float-breakpoint) {
        flex: 1 1 25%;
      }
    }

    &--vertical {
      border-top: 1px solid currentColor;
      width: 100%;

      @media screen and (min-width: $grid-float-breakpoint) {
        max-width: 400px;
      }
    }

    &--cross {
      position: relative;
      margin: $base-spacing 0;

      &:not(:last-child) {
        &::before,
        &::after {
          @include position(absolute, auto 0 -32px 0);
          margin: auto;
          height: 2px;
          width: 36px;
          background-color: currentColor;
          content: "";
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(135deg);
        }
      }

      @media screen and (min-width: $screen-lg-min) {
        margin: 0;

        &::before,
        &:first-child::after,
        &:last-child::after {
          display: none;
        }

        &:first-child,
        &:last-child {
          flex-basis: 100%;
        }

        &:nth-child(2),
        &:nth-child(3) {
          flex-basis: 50%;

          &::after {
            @include position(absolute, 0 auto 0 auto);
            margin: auto;
            height: 2px;
            width: 100%;
            background-color: currentColor;
            content: "";
          }
        }

        &:nth-child(2) {
          &::after {
            transform: rotate(45deg);
          }
        }

        &:nth-child(3) {
          &::after {
            right: 50%;
            transform: rotate(135deg);
          }
        }
      }
    }
  }

  &__item-desc {
    font-size: $font-size-small;
  }

  &__item-icon {
    font-size: 48px;
  }

  &__map {
    margin: $base-spacing 0;
  }
}
