.m-section {
  margin-bottom: $grid-gutter-height;

  &:first-child {
    margin-top: $base-spacing;
  }

  @media screen and (min-width: $screen-lg-min) {
    margin-bottom: 70px;
  }

  &--footer {
    margin-top: auto; //Ensure that footer always stick at the bottom 
  }

  &--hero {
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  // Ensure that nested section doesn't apply m-section styles
  .m-section & {
    margin: revert;
    padding: revert;
  }
}
