.m-footer {
  &--variant-1 {
    max-width: $content-condensed-width;
    margin: 0 auto;
    text-align: center;
  }

  &__info {
    flex-grow: 1;

    @media screen and (min-width: $grid-float-breakpoint) {
      font-size: $font-size-small;
    }
  }

  &__socials {
    font-size: 1.5rem;
  }

  &__restaurants {
    border-bottom: 1px solid currentColor;
  }

  &__heading {
    font-size: 2rem;
  }

  &__text {
    text-align: center;
    margin: 8px 0;
  }

  &__app-badges {
    margin-bottom: floor($base-spacing / 2);
  }
}
