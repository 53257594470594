.m-indicators {
  line-height: 1;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-flex;
  }

  &__btn {
    @extend %button-unstyled;
    padding: 6px;

  }

  &__btn {
    &:before {
      content: "";
      display: block;
      background-color: rgba(#777, .75);
      border-radius: 50%;
      width: 14px;
      height: 14px;
      transition: background-color $base-duration ease-in-out;
  
      .m-indicators--dashes & {
        width: 50px;
        height: 12px;
        border-radius: 8px;
      }
    }

    &.is-active {
      &:before {
        border: 1px solid #777;
      }
    }
  }
}
