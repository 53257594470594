.m-breadcrumb {
  &__list {
    @include list-unstyled;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-bottom: 0;
    }
  }

  &__item {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    line-height: 1;

    &:not(:first-child) {
      font-weight: $font-weight-bold;

      &:before {
        padding: 0 5px;
        content: "\003e";
        font-size: 1.5rem;
      }
    }
  }
}
