$m-nav-item-divider-height: 50px;

.m-nav {
  margin: $base-spacing 0;
  width: 100%;

  &__list {
    list-style: none;
    padding: 16px 0;
    margin: 0;
  }

  &__item {
    display: block;
    padding: $m-nav-item-padding;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &.is-active {
      font-weight: $font-weight-bold;
    }

    &--with-divider {
      &::after {
        content: "";
        display: flex;
        margin: 8px auto 0;
        width: 2px;
        height: 0;
        background-color: currentColor;
        transition: height $base-duration ease-in-out;
      }

      &:hover,
      &:focus {
        text-decoration: none;

        &::after {
          height: $m-nav-item-divider-height;
        }
      }

      & + .dropdown-menu {
        margin-top: -($m-nav-item-divider-height + 16px);
      }
    }
  }
}
