.m-featured {
  margin: floor($base-spacing / 2) 0;

  &--vertical {
    max-width: $content-regular-width;
    margin: floor($base-spacing / 2) auto;
  }

  &__wrapper {
    @media screen and (min-width: $grid-float-breakpoint) {
      margin-bottom: floor($base-spacing * 2);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    &--horizontal {
      @media screen and (min-width: $grid-float-breakpoint) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  &__list-item {
    flex-grow: 1;

    .m-featured__list--horizontal & {
      @media screen and (min-width: $grid-float-breakpoint) {
        flex: 0 1 (100% / 3);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--vertical {
      flex-direction: column;
      padding: $base-spacing 0;
      border-top: 1px solid currentColor;

      @media screen and (min-width: $grid-float-breakpoint) {
        flex-direction: row;
      }
    }

    &--horizontal {
      flex-direction: column;
      margin: floor($base-spacing / 2) 0;
      text-align: center;

      @media screen and (min-width: $grid-float-breakpoint) {
        height: 100%;
        margin: floor($base-spacing / 2);
      }
    }

    &--teasers {
      flex-direction: row;
      align-items: flex-start;
    }

    &--teasers-col {
      margin: $base-spacing floor($base-spacing / 2);
    }
  }

  &__item-content {
    flex-grow: 1;
    width: 100%;

    .m-featured__item--vertical & {
      padding-left: floor($base-spacing / 2);
      padding-right: floor($base-spacing / 2);

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-left: $base-spacing;
        padding-right: $base-spacing;
      }
    }

    .m-featured__item--teasers & {
      padding: revert;
    }
  }

  &__item-img {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    object-fit: cover;

    .m-featured__item--vertical & {
      @media screen and (min-width: $grid-float-breakpoint) {
        width: 160px;
        flex-shrink: 0;
      }
    }

    .m-featured__item--teasers & {
      width: 100px;
      margin-right: floor($base-spacing / 2);
      flex-shrink: 0;

      @media screen and (min-width: $grid-float-breakpoint) {
        width: 160px;
        margin-right: $base-spacing;
      }
    }

    .m-featured__item--teasers-col & {
      width: 140px;
      border-radius: 50%;

      @media screen and (min-width: $grid-float-breakpoint) {
        width: 180px;
      }
    }
  }

  &__item-action {
    margin-bottom: floor($base-spacing / 2);

    .m-featured__item--horizontal & {
      margin: $base-spacing 0;
    }

    .m-featured__item--vertical & {
      align-self: flex-end;

      @media screen and (min-width: $grid-float-breakpoint) {
        align-self: center;
      }
    }
  }

  &__item-description {
    margin-bottom: floor($base-spacing / 2);
  }

  &__title {
    margin-top: $base-spacing;

    .m-featured__item--teasers & {
      margin-top: 0;

      @media screen and (min-width: $grid-float-breakpoint) {
        margin-top: $base-spacing;
      }
    }
  }

  &__availability {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .m-featured__item--horizontal & {
      justify-content: center;
    }

    strong {
      font-weight: $font-weight-base;
    }
  }

  &__item-description {
    .m-featured__item--teasers &,
    .m-featured__item--teasers-col & {
      font-size: $font-size-small;

      @media screen and (min-width: $grid-float-breakpoint) {
        font-size: revert;
      }
    }
  }
}
