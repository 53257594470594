// Layout
$screen-sm-min: 768px;
$container-sm: 100%;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xlg-min: 1640px;
$grid-float-breakpoint: $screen-md-min;
$content-large-width: 1400px;
$content-regular-width: 1000px;
$content-condensed-width: 620px;
$desktop-nav-width: 240px;
$grid-gutter-width: 32px;
$grid-gutter-height: 24px;
$base-spacing: 24px;
$border-radius-base: 12px;
$m-layout-header-mobile-height: 75px;
$m-layout-offset-size: 20px;
$m-layout-header-scrollbar-width: 6px; // 6px is thin scrollbar width on firefox

// Typography
$headings-size-breakpoint: $grid-float-breakpoint;
$font-size-h1: 3rem;
$font-size-h2: 2rem;
$font-size-h3: 1.5rem;
$font-size-h1-gfb: 3.5rem;
$font-size-h2-gfb: 2.5rem;
$font-size-h3-gfb: 1.75rem;
$line-height-base: 1.7;
$font-weight-base: 400;
$font-weight-bold: 600;
$headings-font-weight: $font-weight-bold;
$headings-small-color: inherit;
$font-size-small: 14px; // Bootstrap don't allow `rem` here.

// Nav
$mobile-navigation-fullscreen: true;
$mobile-navigation-flex-middle: true;
$mobile-navigation-bg-color: transparent;
$mobile-navigation-list-text-transform: uppercase;
$m-nav-item-padding: 10px 0;
$mobile-navigation-list-link-padding: 12px 32px;
$mobile-navigation-dropdown-style-disc: false;
$mobile-navigation-header-padding: ($base-spacing / 2);

// Button
$btn-text-transform: uppercase;
$btn-box-shadow: 0 2px 6px 0 rgba(#555, .5);
$btn-padding-base-vertical: 10px;
$btn-padding-base-horizontal: 24px;
$btn-padding-large-vertical: 12px;
$btn-padding-large-horizontal: 56px;
$btn-font-size-base: 1rem;
$btn-font-size-large: 1rem;
$btn-line-height-base: $line-height-base;
$btn-line-height-large: $line-height-base;
$btn-border-radius-base: $border-radius-base;
$btn-border-radius-large: $border-radius-base;
$btn-font-weight: $font-weight-bold;

// Forms
$input-height-base: 48px;
$m-select-padding: 12px 26px 12px 20px;
$labels-font-weight: $font-weight-base;
$form-group-margin-bottom: $base-spacing;

// FAB
$m-floating-action-btn-size: 72px;

// Slider
$m-slider-arrow-size: 40px;
$slider-content-max-width: 950px;
$slider-content-gutter: 96px;

// Card
$card-background: transparent;
$card-padding: 0;
$card-margin-bottom: 0;
$card-box-shadow: none;

// Menu
$m-menu-description-text-align: left;
$m-menu-description-margin: 0 0 $base-spacing;

// Group nav module
$m-group-nav-font-size: 1.25rem;
$m-group-nav-title-padding: $base-spacing;
$m-group-nav-title-font-size: 2.5rem;
$m-group-nav-link-padding: floor($base-spacing / 2);

// Group module
$m-group-header-text-transform: uppercase;

// Item module
$m-item-gutter-base: floor($grid-gutter-width / 2);

// List module
$m-list-title-font-size: 2.5rem;
$m-list-title-text-transform: uppercase;
$m-list-item-inner-border: true;
$m-list-item-border-top: 1px solid transparent; // border-color is defined in theme.scss.liquid

// Gallery
$m-gallery-carousel-max-width: 580px;
$m-gallery-carousel-screen-lg-max-width: 820px;

// Promo section
$m-promo-section-font-size: $font-size-h2;

// Shared
@import 'base/shared-themes-dependencies';
@import 'base/shared-essentials';
@import 'base/shared-themes';

// Custom styles
@import 'modules/m-buttons';
@import 'modules/m-floating-action';
@import 'modules/m-articles';
@import 'modules/m-bubbles';

// Vesspa
@import 'base/vesspa/common';
@import 'modules/vesspa/m-layout';
@import 'modules/vesspa/m-container';
@import 'modules/vesspa/m-section';
@import 'modules/vesspa/m-content';
@import 'modules/vesspa/m-header';
@import 'modules/vesspa/m-footer';
@import 'modules/vesspa/m-nav';
@import 'modules/vesspa/m-brand';
@import 'modules/vesspa/m-hero';
@import 'modules/vesspa/m-slider';
@import 'modules/vesspa/m-indicators';
@import 'modules/vesspa/m-info-boxes';
@import 'modules/vesspa/m-info-box';
@import 'modules/vesspa/m-breadcrumb';
@import 'modules/vesspa/m-featured';
@import 'modules/vesspa/m-testimonials';
@import 'modules/vesspa/m-contact';
@import 'modules/vesspa/m-checkout';
@import 'modules/vesspa/m-steps';
