.m-slider {
  &__slide-content {
    padding: floor($grid-gutter-height / 2) $grid-gutter-width;

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 100%;
      max-width: $slider-content-max-width;
      padding: $grid-gutter-height $slider-content-gutter;
    }
  }

  &__list,
  .splide__track,
  .splide__list {
    height: 100%;
  }

  &__list-item {
    &:not(.is-visible) {
      overflow: hidden;
    }
  }

  &__list-item,
  &__slide-img {
    width: 100%;
  }

  &__arrow {
    @extend %button-unstyled;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
    font-size: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1;

    &--left {
      left: -($m-slider-arrow-size / 2);
      padding-right: 2px;

      @media screen and (min-width: $grid-float-breakpoint) {
        left: -($m-slider-arrow-size);
      }
    }

    &--right {
      right: -($m-slider-arrow-size / 2);
      padding-left: 2px;

      @media screen and (min-width: $grid-float-breakpoint) {
        right: -($m-slider-arrow-size);
      }
    }
  }

  &__indicators {
    @include position(absolute, null 0 floor($grid-gutter-height / -1) 0);
    text-align: center;

    &--outer {
      bottom: floor($grid-gutter-height / 2);

      @media screen and (min-width: $grid-float-breakpoint) {
        bottom: $grid-gutter-height;
      }
    }
  }

  &__title {
    @media screen and (min-width: $grid-float-breakpoint) {
      font-size: 5rem;
    }
  }
}
