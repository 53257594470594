.m-steps {
  max-width: $content-regular-width;
  margin: 0 auto $base-spacing;

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex-direction: row;
    }
  }

  &__list-item {
    position: relative;
    margin: 0 auto;
    padding: 0 floor($grid-gutter-width / 2);
    flex: 1 1 auto;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex-basis: 250px;
    }
  }

  &__item {
    text-align: center;

    &--bubbles {
      padding-bottom: floor($base-spacing * 2);

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-bottom: revert;
      }
    }
  }

  &__media {
    position: relative;
    display: flex;

    .m-steps__item--boxes & {
      width: 120px;
      height: 120px;
      margin: 0 auto;
      border-radius: $border-radius-base;
      background-color: rgba(#fff, .8);
      color: #000;
    }

    .m-steps__item--bubbles & {
      width: 150px;
      height: 150px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: rgba(#000, .55);
      color: #fff;
    }
  }

  &__separator {
    font-size: 36px;
    text-align: center;
    align-self: center;
    margin: floor($base-spacing / 2);
    transform: rotate(90deg);

    @media screen and (min-width: $grid-float-breakpoint) {
      margin: revert;
      align-self: start;
      transform: rotate(0);
    }

    .m-steps--bubbles & {
      display: none;
    }

    .m-steps--boxes & {
      @media screen and (min-width: $grid-float-breakpoint) {
        margin-top: 30px;
      }
    }
  }

  &__icon {
    margin: auto;
    line-height: 1;
  }

  &__badge {
    @include position(absolute, 0 null null 0);
    display: none;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 24px;
    font-weight: $font-weight-bold;

    .m-steps--bubbles & {
      display: flex;
    }
  }

  &__title {
    margin-top: floor($base-spacing / 2);
    margin-bottom: floor($base-spacing / 3);

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-top: $base-spacing;
      margin-bottom: floor($base-spacing / 2);
    }
  }
}
