.m-brand {
  display: flex;
  margin: 0;

  &__area {
    max-width: 50px;

    @media screen and (min-width: $grid-float-breakpoint) {
      max-width: initial;
    }
  }
}
