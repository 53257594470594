.m-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);

  @media screen and (min-width: $grid-float-breakpoint) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  @media screen and (min-width: $screen-md-min) {
    max-width: $content-regular-width;
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  @media screen and (min-width: $screen-xlg-min) {
    max-width: $content-large-width;
  }

  &__content {
    margin-left: auto;
    margin-right: auto;

    &--condensed {
      max-width: $content-condensed-width;
    }
  
    &--regular {
      max-width: $content-regular-width;
    }
  
    &--large {
      max-width: $content-large-width;
    }
  
    &--full {
      width: 100%;
    }
  }
}
